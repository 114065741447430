<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model.trim="query.account" clearable placeholder="员工手机号" class="handle-input mr10" style="width: 180px"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch">搜索</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column prop="devicecode" label="设备" align="center">
					<template #default="scope">
						<el-tag v-if="scope.row.devicecode == 'applet'">小程序</el-tag>
						<el-tag v-else>{{ scope.row.devicecode }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="ip" label="ipv4地址" align="center"></el-table-column>
				<el-table-column prop="loginTime" label="登录时间" align="center"></el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import { getloginRecord } from '../api/index.js';
export default {
	name: 'user',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				account: ''
			},
			tableData: []
		};
	},
	created() {
		const role = localStorage.getItem('ms_role');
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},

		getData() {
			let data = {
				userPhone: this.query.account
			};
			getloginRecord(data).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.loginList;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 12px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline-block;
}

.handle-select {
	width: 120px;
}

.handle-input {
	width: 300px;
	display: inline-block;
}
.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}
.grid-con-icon {
	font-size: 50px;
	width: 100px;
	height: 100px;
	text-align: center;
	line-height: 100px;
	color: #fff;
}
.grid-cont-right {
	flex: 1;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #999;
}
.grid-content {
	display: flex;
	align-items: center;
	height: 100px;
}
</style>
